@tailwind base;
@tailwind components;
@tailwind utilities;


.fade-in {
  opacity: 0;
  animation: fadeInKeyframe 0.5s cubic-bezier(0.22, 0.1, 0.36, 1) forwards;
}

@keyframes fadeInKeyframe {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-out {
  opacity: 1;
  animation: fadeOutKeyframe 0.5s cubic-bezier(0.22, 0.1, 0.36, 1) forwards;
}

@keyframes fadeOutKeyframe {
  from { opacity: 1; }
  to { opacity: 0; }
}





// sup-mapp-footer

.sup-mapp-footer.sup-home-page {
  transform: translateY(75px);
  opacity: 0;
  animation: slideUpFadeKeyframe 1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.sup-mapp-footer.sup-not-home-page {
  transform: translateY(0);
  animation: none;
}

@keyframes slideUpFadeKeyframe {
  from {
    opacity: 0;
    transform: translateY(75px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// sup-mapp-nav

.sup-home-page .sup-mapp-nav-button {
  opacity: 0;
  transform: translateX(-50px);
  animation: slideInLeftKeyframe 0.8s ease forwards;
}

.sup-not-home-page .sup-mapp-nav-button {
  opacity: 1;
  transform: translateX(0);
  animation: none;
}

@keyframes slideInLeftKeyframe {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sup-home-page .sup-mapp-nav .sup-mapp-nav-button:nth-child(1) {
  animation-delay: 0.3s;
}
.sup-home-page .sup-mapp-nav .sup-mapp-nav-button:nth-child(2) {
  animation-delay: 0.4s;
}
.sup-home-page .sup-mapp-nav .sup-mapp-nav-button:nth-child(3) {
  animation-delay: 0.5s;
}
.sup-home-page .sup-mapp-nav .sup-mapp-nav-button:nth-child(4) {
  animation-delay: 0.6s;
}




.sup-animate {
  opacity: 0;
  animation: animateKeyframe 1s ease forwards;
}

@keyframes animateKeyframe {
  from { opacity: 0; }
  to { opacity: 1; }
}


.sup-animate-parent:nth-child(1) {
  animation-delay: 0.3s;
}
.sup-animate-parent:nth-child(2) {
  animation-delay: 0.4s;
}
.sup-animate-parent:nth-child(3) {
  animation-delay: 0.5s;
}
.sup-animate-parent:nth-child(4) {
  animation-delay: 0.6s;
}



svg:has(> use) {
  height: 100%;
  width: 100%;
  position: absolute;
}

.geosuggest__input {
  outline: none;
}


#franchise p {
  font-size: 14px;
}

#franchise ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 14px;

  li {
    line-height: 1;
  }
}

#franchise img {
  position: relative;
  height: 221.625px;
  align-self: stretch;
}

#franchise h1,
#franchise h2 {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  #franchise img {
    height: 511.625px;
  }
}

.sup-card {
  background-color: var(--surface-surface, #1f1f1f);
}

.sup-link {
  color: var(--accents-secondary-accent, #3486D9);
}

.sup-service-btn-delivery {
  background-color: var(--accents-primary-button);
}

.sup-service-btn-pickup {
  background-color: var(--accents-primary-accent);
}



.sup-notes {
  border-color: #ffffff;
}


.sup-checkbox-unchecked {
  color: var(--accents-secondary-accent);
}

.sup-checkbox-checked {
  border: none;
}


.slick-list {
  flex-grow: 1;
}